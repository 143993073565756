import axios from "axios";
import {API_BASE_URL, API_JSON_BASE_URL} from "../config";

/*const request = axios.create({
    baseURL: API_BASE_URL,
    timeout: 30 * 1000,
});*/

const request = axios.create({
    baseURL: API_BASE_URL,
});

const jsonAPI = axios.create({
    baseURL: API_JSON_BASE_URL,
});

const flightInsiderApi = {
    getHomePageData: () => {
        return new Promise((resolve, reject) => {
            request
                .get('getJSONData.php', {
                    params :{
                        name: 'homePageData.json',
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },
    getSiteSetting: () => {
        return new Promise((resolve, reject) => {
            request
                .get("getJSONData.php", {
                    params :{
                        name: 'siteSettings.json',
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    getBookingFromSetting: () => {
        return new Promise((resolve, reject) => {
            request
                .get("getJSONData.php", {
                    params :{
                        name: 'getBookingFromSettings.json',
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    addBookingRequest: (body) => {

        let form_data = new FormData();

        for ( let key in body ) {
            form_data.append(key, body[key]);
        }

        return new Promise((resolve, reject) => {
            request
                .post("addBookingRequest.php", form_data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    subscribeEmail: (body) => {
        let form_data = new FormData();
        for ( let key in body ) {
            form_data.append(key, body[key]);
        }
        return new Promise((resolve, reject) => {
            request
                .post("subscribeEmail.php", form_data)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    getAirPorts: (search_str) => {
        return new Promise((resolve, reject) => {
            request
                .get("get_airports.php", {
                    params: {search_str}
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

};


export default flightInsiderApi;
