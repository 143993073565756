import { useState } from "react";
import { useGetAllHomePageData } from "./../queries/hooks/index";
import { IMG_BASE_URL } from "../config/index";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
function Destinations({ onSetDestinationsHandler }) {
    const { data, isFetching } = useGetAllHomePageData();
    const destinations = data?.destinations;
    const options = {
        loop: true,
        margin: 10,
        // nav: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 4,
            },
        },
        nav: false,
    };
    return (
        <div className="recent_flights">
            <div className="container">
                <div className="d-flex justify-content-center">
                    <h2 className="section_heading text-center">Real Recently Booked Flights</h2>
                </div>
                <div className="recent_flights_swiper_parent">
                    {/* <!-- Slider main container --> */}
                    <div className="swiper recent_flights_swiper">
                        {/* <!-- Additional required wrapper --> */}
                        <div className="swiper-wrapper">
                            {isFetching ? (
                                ""
                            ) : (
                                <OwlCarousel
                                    {...options}
                                    className="owl-theme"
                                    loop
                                    margin={22}
                                    items={4}
                                    autoplay={true}
                                    autoplayTimeout={3000}
                                    autoplayHoverPause={true}
                                    mouseDrag
                                >
                                    {destinations.map((destination) => (
                                        <div
                                            className="swiper-slide"
                                            key={destination?.id}
                                            onClick={() =>
                                                onSetDestinationsHandler({
                                                    from_airport: {
                                                        id: destination?.from_airport,
                                                        name: destination?.from_airport_name,
                                                    },
                                                    to_airport: {
                                                        id: destination?.to_airport,
                                                        name: destination?.to_airport_name,
                                                    },
                                                })
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="card recent_flight_card">
                                                <div className="recent_flight_card_img">
                                                    <img src={`${IMG_BASE_URL}destinations/${destination.image}`} alt={destination?.title} />
                                                    <div className="overlay_text">
                                                        <h4>{`${destination?.title}`}</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <h5>
                                                        <span className="old_price">${destination?.price}</span>
                                                        {`  $${destination?.discounted_price}`}
                                                        <span className="sale">
                                                            <span className="sale_line"> |</span>
                                                            {` ${(((destination?.price - destination?.discounted_price) / destination?.price) * 100).toFixed(
                                                                2
                                                            )}% Off`}
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: destination?.sub_title,
                                                                }}
                                                            />
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Destinations;
