import { useFormik } from "formik";
import * as yup from "yup";

// imported mutation hook from hooks
import { useSubscribeEmail } from "../queries/hooks/index";

// validation schema
const validationSchema = yup.object({
  email: yup.string().email().required(),
});
function Subscription(props) {
  const subscribe = useSubscribeEmail();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      subscribe.mutate(values);
    },
  });
  return (
    <div className="container">
      <div className="newsletter_footer_parent">
        <div className="nf_bag">
          <img src="images/footer_bag.png" alt="Bag" />
        </div>
        <div className="newsletter_footer">
          <div className="nf_circle_lg"></div>
          <div className="nf_circle"></div>
          <div className="row justify-content-center">
            <div className="col-sm-6">
              <img className="nf_fire_img" src="images/camp_fire.png" alt="" />
              <h2>
                Sign Up & Save <span>Up To 50% Off Business & First Class</span>
              </h2>
              <p>
                or Up to 40% Off Economy and Premium Economy
              </p>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button
                  disabled={!(formik.isValid && formik.dirty)}
                  type="submit"
                  onClick={formik.handleSubmit}
                  className="btn global_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#emailSubscribeModal"
                >
                  <span className="web_txt">Sign Up</span>
                  <span className="mob_txt">Go</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Thank You Modal --> */}

      {subscribe?.isSuccess && (
        <div
          className="modal fade custom_modal custom_modal2"
          id="emailSubscribeModal"
          tabIndex="-1"
          aria-labelledby="emailSubscribeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="container">
                <div className="modal-header">
                  <div className="modal_logo">
                    <a href="/">
                      <img src="images/logo.png" alt="Logo" />
                    </a>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body text-center">
                  <img src="images/large_plane.png" alt="Large Plane" />
                  <h1>
                    {subscribe?.data?.status === true ? "Thank You!" : "Oops!"}
                  </h1>
                  <h5>{subscribe?.data?.message}</h5>
                  <button
                    type="button"
                    className="btn global_btn"
                    data-bs-dismiss="modal"
                  >
                    Go Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <!-- Mobile Thank You Modal --> */}

      {subscribe?.isSuccess && (
        <div
          className="modal fade custom_modal3 custom_modal4"
          id="emailSubscribeModal"
          tabindex="-1"
          aria-labelledby="emailSubscribeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-body">
                <div className="w-100">
                  <img src="images/mobile_thankyou_modal.png" alt="" />
                  <h1>
                    {subscribe?.data?.status === true ? "Thank You!" : "Oops!"}
                  </h1>
                  <h5>{subscribe?.data?.message}</h5>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn" data-bs-dismiss="modal">
                  Go Home
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Subscription;
