import { useMutation, useQuery } from "react-query";
import {
  GET_HOME_PAGE_DATA,
  GET_SITE_SETTING,
  GET_BOOKING_FORM_SETTING,
  SUBSCRIBE_EMAIL_MUT,
  ADD_BOOKING_REQUEST_MUT,
  GET_AIR_PORT_MUT,
} from "../keys";
import Services from "../../services/index";

// Get All Data for Home Page
export const useGetAllHomePageData = () => {
  const res = useQuery(GET_HOME_PAGE_DATA, Services.getHomePageData, {
    select: (data) => {
      return data;
    },
  });
  return res;
};

// Get Site Setting
export const useGetSiteSetting = () => {
  const res = useQuery(GET_SITE_SETTING, Services.getSiteSetting, {
    select: (data) => {
      return data;
    },
  });
  return res;
};

// Get Booking Data information Setting
export const useGetBookingFormSetting = () => {
  const res = useQuery(
    GET_BOOKING_FORM_SETTING,
    Services.getBookingFromSetting,
    {
      select: (data) => {
        return data;
      },
    }
  );
  return res;
};

// Subscribe Email
export const useSubscribeEmail = () => {
  const res = useMutation(SUBSCRIBE_EMAIL_MUT, Services.subscribeEmail, {
    onSuccess: (data) => {
      // console.log(data);
    },
    onError: (data) => {},
  });
  return res;
};

// Add Booking Request
export const useAddBookingRequest = () => {
  const res = useMutation(ADD_BOOKING_REQUEST_MUT, Services.addBookingRequest, {
    onSuccess: (data) => {
      // console.log(data);
    },
    onError: (data) => {},
  });
  return res;
};

// Add Booking Request
export const useGetAirports = () => {
  const res = useMutation(GET_AIR_PORT_MUT, Services.getAirPorts, {
    onSuccess: (data) => {
      // console.log(data);
    },
    onError: (data) => {},
  });
  return res;
};

// Add TEST PHP Request
export const useGetTest = () => {
  const res = useMutation(GET_AIR_PORT_MUT, Services.getTest, {
    onSuccess: (data) => {
      // console.log(data);
    },
    onError: (data) => {},
  });
  return res;
};
