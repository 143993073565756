// Queries
export const GET_HOME_PAGE_DATA = "getHomePageData";
export const GET_SITE_SETTING = "getSiteSetting";
export const GET_BOOKING_FORM_SETTING = "getBookingFormSetting";

// Mutations
export const SUBSCRIBE_EMAIL_MUT = "subscribeEmail";
export const ADD_BOOKING_REQUEST_MUT = "addBookingRequest";
export const GET_AIR_PORT_MUT = "getAirports";
export const GET_TEST_MUT = "getTest";
