import { siteSettings } from '../content/siteSettings';

function Header() {
    return (
        <>
            {/* <!-- main Header --> */}
            <nav id="main_header" className="navbar navbar-expand navbar-light sticky-top">
                <div className="container">
                    <div className="main_header_child">
                        <a className="navbar-brand" href="/">
                            <img src="images/logo.svg" alt="Logo" />
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className="offcanvas offcanvas-end offcanvasNavbar justify-content-center"
                            tabIndex="-1"
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                        >
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                                    Menu
                                </h5>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href={`tel:${siteSettings.phone}`}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Phone"
                                            >
                                                <div className="d-flex align-items-center">
                                                    <span>
                                                        <div className="header_icons me-2">
                                                            <img src="images/header_phone_icon.png" alt="" className="home_img" />
                                                            <img src="images/header_phone_icon_w.png" alt="" className="inner_img" />
                                                        </div>
                                                    </span>
                                                    {`${siteSettings.phone}`}
                                                </div>
                                            </a>
                                        </li>
                                        <hr />
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                target="_blank"
                                                rel="noreferrer"
                                                href={`${siteSettings.instagram}`}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Instagram"
                                            >
                                                <span>
                                                    <div className="header_icons">
                                                        <img src="images/header_instagram_icon.png" alt="" className="home_img" />
                                                        <img src="images/header_instagram_icon_w.png" alt="" className="inner_img" />
                                                    </div>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                className="nav-link"
                                                href={`https://api.whatsapp.com/send?phone=${siteSettings.whatsapp}`}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="WhatsApp"
                                            >
                                                <span>
                                                    <div className="header_icons">
                                                        <img src="images/header_whatsapp_icon.png" alt="" className="home_img" />
                                                        <img src="images/header_whatsapp_icon_w.png" alt="" className="inner_img" />
                                                    </div>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {/* <!-- Mobile Header --> */}
            <div className="mobile_header">
                <div className="container">
                    <div className="mobile_header_inner">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <a className="navbar-brand" href="/">
                                    <img src="images/logo.svg" alt="Logo" />
                                </a>
                            </div>
                                <div className="col-sm-6 text-end">
                                    <a className="nav_link" href={`tel:${siteSettings.phone}`}>
                                        <span>
                                            <i className="fa-solid fa-phone me-2"></i>
                                        </span>
                                        {`${siteSettings.phone}`}
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
