import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import postscribe from "postscribe";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../helpers/utils";
import { useGetSiteSetting } from "./../queries/hooks/index";

import "../styles/base.scss";

function ThankyouWeb() {
  const { data } = useGetSiteSetting();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isMobile) {
      postscribe(
        "#thankyouScript",
        '<script>!(function (w, d, t) {w.TiktokAnalyticsObject = t;var ttq = (w[t] = w[t] || []);(ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"]),  (ttq.setAndDefer = function (t, e) {    t[e] = function () {      t.push([e].concat(Array.prototype.slice.call(arguments, 0)));    };  });for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);(ttq.instance = function (t) {  for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);  return e;}),  (ttq.load = function (e, n) {    var i = "https://analytics.tiktok.com/i18n/pixel/events.js";    (ttq._i = ttq._i || {}),      (ttq._i[e] = []),      (ttq._i[e]._u = i),      (ttq._t = ttq._t || {}),      (ttq._t[e] = +new Date()),      (ttq._o = ttq._o || {}),      (ttq._o[e] = n || {});    var o = document.createElement("script");    (o.type = "text/javascript"), (o.async = !0), (o.src = i + "?sdkid=" + e + "&lib=" + t);    var a = document.getElementsByTagName("script")[0];    a.parentNode.insertBefore(o, a);  });ttq.load("CDKJ6JRC77U9BMO6OR10");ttq.page();})(window, document, "ttq");ttq.track("SubmitForm");</script>'
      );
    }
  }, []);

  const setShowSuccessModal = () => {
    navigate("/");
  };

  return (
    <div>
      <div id="thankyouScript"></div>
      {!isMobile ? (
        <div className="webThankYouModal">
          <div className="container">
            <div className="modal-header">
              <div className="modal_logo">
                <a href="/">
                  <img src="images/logo.png" alt="Logo" />
                </a>
              </div>
              <button type="button" className="btn-close" onClick={() => setShowSuccessModal(false)}></button>
            </div>
            <div className="modal-body text-center">
              <img src="images/large_plane.png" alt="Large Plane" />
              <h5>Thank You for Submitting Your Request!</h5>
              <div className="d-flex justify-content-center">
                <hr/>
              </div>
              <h2>What Happens Now?</h2>
              <p>One of our Flight Insiders is already busy working on the best Deals for your request. You should receive a quote within minutes. If you would like to get in touch with us, or have any questions, please <strong>call us</strong> at <strong><a href={`tel:${data?.site_settings?.phone}`}>{`${data?.site_settings?.phone}`}</a></strong> anytime.</p>
              <div className="row justify-content-center g-0 what_happens_box_row">
                <div className="col-sm-3">
                  <div className="what_happens_box">
                    <i className="fa-solid fa-laptop"></i>
                    Get in touch with us
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="what_happens_box">
                    <i className="fa-solid fa-user-tie-hair"></i>
                    One of our Flight Insiders searches the best options for you
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="what_happens_box">
                    <i className="fa-solid fa-plane-up"></i>
                    Receive your options, book, and save
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row align-items-center justify-content-center w-100">
                <div className="col-sm-3 text-center">
                  <a href={`tel:${data?.site_settings?.phone}`}>
                    <i className="fa-solid fa-phone"></i>
                    {`${data?.site_settings?.phone}`}
                  </a>
                </div>
                <div className="col-sm-3 text-center">
                  <a target="_blank" rel="noreferrer" href={`${data?.site_settings?.instagram}`}>
                    <i className="fa-brands fa-instagram"></i>
                    flight.insiders
                  </a>
                </div>
                <div className="col-sm-3 text-center">
                  <a href={`https://api.whatsapp.com/send?phone=${formatPhoneNumber(data?.site_settings?.whatsapp)}`}>
                    <i className="fa-brands fa-whatsapp"></i>
                    Chat with us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="custom_modal3 custom_modal4 webThankYouModal mobThankYouModal">
          <div id="thankyouMobileScript"></div>
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-body">
                <div className="w-100">
                  <div className="px-3">
                    <button type="button" className="btn back_arrow_btn" onClick={() => setShowSuccessModal(false)}><i className="fa-regular fa-arrow-left-long"></i></button>
                  </div>
                  <img src="images/mobile_thankyou_modal.png" alt="" />
                  <div className="px-3 mt-3">
                    <h5>Thank You for Submitting Your Request!</h5>
                    <div className="d-flex justify-content-center">
                      <hr/>
                    </div>
                    <h2>What Happens Now?</h2>
                    <p>One of our Flight Insiders is already busy working on the best Deals for your request. You should receive a quote within minutes.</p>
                    <p> If you would like to get in touch with us, or have any questions, please call us at <strong><a href={`tel:${data?.site_settings?.phone}`}>{`${data?.site_settings?.phone}`}</a></strong> anytime.</p>
                    <div className="row justify-content-center g-0 what_happens_box_row">
                      <div className="col-sm-3">
                        <div className="what_happens_box">
                          <i className="fa-solid fa-laptop"></i>
                          Get in touch with us
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="what_happens_box">
                          <i className="fa-solid fa-user-tie-hair"></i>
                          One of our Flight Insiders searches the best options for you
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="what_happens_box">
                          <i className="fa-solid fa-plane-up"></i>
                          Receive your options, book, and save
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="row align-items-center w-100">
                  <div className="col-sm-3">
                    <a href={`tel:${data?.site_settings?.phone}`}>
                      <i className="fa-solid fa-phone"></i>
                      {`${data?.site_settings?.phone}`}
                    </a>
                  </div>
                  <div className="col-sm-3">
                    <a target="_blank" rel="noreferrer" href={`${data?.site_settings?.instagram}`}>
                      <i className="fa-brands fa-instagram"></i>
                      flight.insiders
                    </a>
                  </div>
                  <div className="col-sm-3">
                    <a href={`https://api.whatsapp.com/send?phone=${formatPhoneNumber(data?.site_settings?.whatsapp)}`}>
                      <i className="fa-brands fa-whatsapp"></i>
                      Chat with us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ThankyouWeb;
