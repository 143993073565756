import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useGetAllHomePageData } from "./../queries/hooks/index";
import { IMG_BASE_URL } from "../config";
function Airlines() {
    const { data, isLoading } = useGetAllHomePageData();
    const options = {
        loop: true,
        margin: 10,
        responsive: {
            0: {
                items: 2,
            },
            600: {
                items: 4,
            },
            1000: {
                items: 6,
            },
        },
        nav: false,
    };
    return (
        <div className="airlines">
            <div className="container">
                <div className="d-flex justify-content-center">
                    <h2 className="section_heading text-center">We Work With All Major Airlines</h2>
                </div>
                {/* <!-- Slider main container --> */}
                <div className="swiper airlines_swiper">
                    {/* <!-- Additional required wrapper --> */}
                    <div className="swiper-wrapper">
                        {/* <!-- Slides --> */}
                        {!isLoading && (
                            <OwlCarousel
                                {...options}
                                className="owl-theme"
                                loop
                                margin={10}
                                items={6}
                                autoplay={true}
                                autoplayTimeout={3000}
                                autoplayHoverPause={true}
                                mouseDrag
                            >
                                {data?.airlines.map((airline) => (
                                    <div className="item swiper-slide" key={airline.id}>
                                        {/* <a target="_blank" href="https://www.lufthansa.com"> */}
                                        <a href="/">
                                            <div className="card airline_card">
                                                <img src={`${IMG_BASE_URL}airlines/${airline.image}`} alt="Airline" />
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </OwlCarousel>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Airlines;
