export const showTravellers = (value, label, pluralize) => {
    return value > 0 && `${value} ${label}${pluralize && checkPlural(value)}, `;
};

export const checkPlural = (number) => {
    return number > 1 ? "s" : "";
};

export const formatPhoneNumber = (phone) => {
    return phone && phone.replace(" ", "").replace("-", "").replace("(", "").replace(")", "");
};
