import React from "react";

function Features(props) {
  return (
    <div className="features">
      <div className="container">
        <div className="d-flex justify-content-center">
          <h2 className="section_heading text-center">
            Genuine Flight Insiders, Real Inside Pricing
          </h2>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="card feature_card">
              <div className="d-flex justify-content-center">
                <div className="feature_card_img">
                  <img src="images/feature1.png" alt="Icon" />
                </div>
              </div>
              <h5>True Luxury Travel Experts</h5>
              <p>
              Our Insiders are knowledgeable, experienced, and ready to help you take advantage of deals not available to the public!
              </p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card feature_card">
              <div className="d-flex justify-content-center">
                <div className="feature_card_img">
                  <img src="images/feature2.png" alt="Icon" />
                </div>
              </div>
              <h5>Custom Tailored Options</h5>
              <p>Every quote is thoroughly searched by one of our real live Flight Insiders!</p>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card feature_card">
              <div className="d-flex justify-content-center">
                <div className="feature_card_img">
                  <img src="images/feature3.png" alt="Icon" />
                </div>
              </div>
              <h5>Maximum Savings</h5>
              <p>We work with all major airlines directly, as well as over 100+ wholesalers around the globe to find you the best possible price!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
