import { IMG_BASE_URL } from "../config";
import { siteSettings } from '../content/siteSettings';
import { PrivacyPolicy } from "./PrivacyPolicy";

function Footer() {
    return (
        <>
            <div className="main_footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 mobile_d_none">
                            <div className="footer_logo">
                                <a href="/">
                                    <img src="images/footer_logo.svg" alt="Footer Logo" />
                                </a>
                            </div>
                            <p>Real travel experts with real inside knowledge, helping you get the inside price!</p>
                            <h6>
                                <a href={`tel:${siteSettings.phone}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Phone">
                                    <img className="me-2" src="images/phone_icon.png" alt="Phone" />
                                    {siteSettings.phone}
                                </a>
                            </h6>
                            <h6>
                                <a href={`mailto:${siteSettings.email}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Email">
                                    <img className="me-2" src="images/mail_icon.png" alt="Email" />
                                    {siteSettings.email}
                                </a>
                            </h6>
                        </div>
                        <div className="col-sm-4 offset-sm-1 mobile_d_none">
                            <h4>Instagram</h4>
                            <div className="row footer_instagram_gallery">
                                <div className="col-sm-12">
                                    <a href={`${siteSettings.instagram_module.link}`} target="_blank" rel="noreferrer" className="instagram_img">
                                        <img
                                            src={`${IMG_BASE_URL}instagrams/${siteSettings.instagram_module.image}`}
                                            alt="instagram"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="footer_accredit_section">
                                <div className="row mb-5">
                                    <div className="col-sm-4">
                                        <img src="images/accredit1.png" alt="ASTA" />
                                    </div>
                                    <div className="col-sm-4">
                                        <img src="images/accredit2.png" alt="arc" />
                                    </div>
                                </div>
                                <h4 className="mobile_d_none">Follow us</h4>
                                <div className="footer_social_icons mobile_d_none">
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${siteSettings.instagram}`}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Instagram"
                                    >
                                        <i className="fa-brands fa-instagram"></i>
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${siteSettings.facebook}`}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Facebook"
                                    >
                                        <i className="fa-brands fa-facebook-f"></i>
                                    </a>
                                    {/* Perhaps twitter? (<i className="fa-brands fa-twitter"></i>) */}
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://api.whatsapp.com/send?phone=${siteSettings.whatsapp}`}
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="WhatsApp"
                                    >
                                        <i className="fa-brands fa-whatsapp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://api.whatsapp.com/send?phone=${siteSettings.whatsapp}`}
                    className="whatsapp_link"
                >
                    <i className="fa-brands fa-whatsapp"></i>
                </a>
            </div>

            {/* <!-- Copyright Footer --> */}
            <div className="copyright_footer">
                <div className="container">
                    <div className="text-center">
                        <p>
                            © Copyright 2022 All Rights Reserved. |{" "}
                            <a 
                                href="#termsModal" 
                                data-bs-toggle="modal" 
                                data-bs-target="#termsModal" 
                                style={{ cursor: "pointer", color: "white" }}>
                                Privacy Policy and Terms & Conditions
                            </a>
                        </p>
                        <div className="footer_social_icons footer_social_icons2">
                            <a target="_blank" href={`${siteSettings.instagram}`} rel="noreferrer" data-bs-toggle="tooltip" data-bs-placement="top" title="Instagram">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a target="_blank" href={`${siteSettings.facebook}`} rel="noreferrer" data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook">
                                <i className="fa-brands fa-facebook-f"></i>
                            </a>
                            {/* Perhaps twitter? (<i className="fa-brands fa-twitter"></i>) */}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://api.whatsapp.com/send?phone=${siteSettings.whatsapp}`}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="WhatsApp"
                            >
                                <i className="fa-brands fa-whatsapp"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Privacy Policy*/}
            <PrivacyPolicy />
        </>
    );
}

export default Footer;
