import FlightDetail from "./Pages/FlightDetail";
import Home from "./Pages/Home";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./queries/index";
import ThankyouWeb from "./Components/ThankyouWeb";

function App() {
  let params = new URLSearchParams(document.location.search);

  let paramsObject = {};
  for (let [key, value] of params.entries()) {
      paramsObject[key] = value;
  }
  sessionStorage.setItem('urlParams', JSON.stringify(paramsObject));
  
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        {/*<Router basename={"/flight"}>*/}
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/flight-detail" element={<FlightDetail />} />
            <Route exact path="/thankyou" element={<ThankyouWeb />} />
          </Routes>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
