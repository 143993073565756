const host = window.location.host;

function set(key, value) {
  if (value) {
    localStorage.setItem(`${host}_${key}`, JSON.stringify(value));
  }
}

function get(key) {
  const value = localStorage.getItem(`${host}_${key}`);

  if (value) {
    return JSON.parse(value);
  }

  return null;
}

const localStorageService = {
  set,
  get,
  remove: (key) => localStorage.removeItem(`${host}_${key}`),
  clear: () => localStorage.clear(),
};

export default localStorageService;
