import { useState } from "react";
import { useFormik } from "formik";

import Destinations from "../Components/destinations";
import Subscription from "../Components/subscription";
import Footer from "../Components/footer";

import { useGetSiteSetting, useGetBookingFormSetting, useGetAirports, useAddBookingRequest } from "../queries/hooks/index";
import localStorageService from "../helpers/localStorageService";
import { formatPhoneNumber } from "../helpers/utils";

import "../styles/base.scss";

function FlightDetail() {
    function toggledetinations() {
        var from_airport = formik.values.from_airport;
        var to_airport = formik.values.to_airport;
        var to_airpot_name = localStorageService.get("to_airpot_name");
        var from_airpot_name = localStorageService.get("from_airpot_name");
        setFrom_airport(to_airpot_name);
        setTo_airport(from_airpot_name);
        formik.setFieldValue("to_airport", from_airport);
        formik.setFieldValue("from_airport", to_airport);
        localStorageService.set("from_airpot_name", to_airpot_name);
        localStorageService.set("to_airpot_name", from_airpot_name);
    }
    const { data, isFetching } = useGetSiteSetting();
    const { data: formData, isFetching: fetching } = useGetBookingFormSetting();
    const getAirPorts = useGetAirports();
    const addBooking = useAddBookingRequest();
    const formik = useFormik({
        initialValues: {
            from_airport: "",
            to_airport: "",
            trip_type: 1,
            departure_date: "",
            return_date: "",
            travellers: "",
            class: "",
        },
        onSubmit: (values) => {
            localStorageService.set("firstFormValues", values);
        },
    });

    // implementation of auto-suggestion
    const [active, setActive] = useState(0);
    const [filtered, setFiltered] = useState([]);

    const [isShowFrom, setIsShowFrom] = useState(false);
    const [isShowTo, setIsShowTo] = useState(false);
    const [from_airport, setFrom_airport] = useState("");
    const [to_airport, setTo_airport] = useState("");

    const FromAirportChangeHandler = async (e) => {
        e.preventDefault();
        const search_str = e.currentTarget.value;
        setFrom_airport(e.currentTarget.value);
        if (search_str.length > 2) {
            const data = new FormData();
            data.append("search_str", search_str);
            const reponse = await getAirPorts.mutateAsync(data);

            setActive(0);
            setFiltered(reponse?.airports);
            setIsShowFrom(true);
        }
    };
    const ToAirPortChangeHandler = async (e) => {
        e.preventDefault();
        const search_str = e.currentTarget.value;
        setTo_airport(e.currentTarget.value);
        if (search_str.length > 2) {
            const data = new FormData();
            data.append("search_str", search_str);
            const reponse = await getAirPorts.mutateAsync(data);

            setActive(0);
            setFiltered(reponse?.airports);
            setIsShowTo(true);
        }
    };
    const FromAirPortOnClick = (e, id, name) => {
        setActive(0);
        setFiltered([]);
        setIsShowFrom(false);
        setFrom_airport(name);
        formik.setFieldValue("from_airport", id);
        localStorageService.set("fromAirPort", name);
    };
    const ToAirPortOnClick = (e, id, name) => {
        setActive(0);
        setFiltered([]);
        setIsShowTo(false);
        setTo_airport(name);
        formik.setFieldValue("to_airport", id);
        localStorageService.set("toAirPort", name);
    };
    const FromAirPortOnKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter key
            setActive(0);
            setIsShowFrom(false);
            setFrom_airport(filtered[active]);
        } else if (e.keyCode === 38) {
            // up arrow
            return active === 0 ? null : setActive(active - 1);
        } else if (e.keyCode === 40) {
            // down arrow
            return active - 1 === filtered?.length ? null : setActive(active + 1);
        }
    };
    const ToAirPortOnKeyDown = (e) => {
        if (e.keyCode === 13) {
            // enter key
            setActive(0);
            setIsShowTo(false);
            setTo_airport(filtered[active]);
        } else if (e.keyCode === 38) {
            // up arrow
            return active === 0 ? null : setActive(active - 1);
        } else if (e.keyCode === 40) {
            // down arrow
            return active - 1 === filtered?.length ? null : setActive(active + 1);
        }
    };
    const FromAirPortRenderAutocomplete = () => {
        if (isShowFrom && from_airport) {
            if (filtered.length) {
                return (
                    <ul className="autocomplete_from">
                        {filtered?.map((suggestion, index) => {
                            let className;
                            if (index === active) {
                                className = "active";
                            }
                            return (
                                <li
                                    className={className}
                                    key={suggestion.id}
                                    onClick={(e) => {
                                        FromAirPortOnClick(e, suggestion.id, suggestion.name);
                                        var airpot_name = suggestion.name;
                                        localStorageService.set("from_airpot_name", airpot_name);
                                    }}
                                >
                                    <i className="fas fa-plane"></i>
                                    {suggestion.name}
                                    <p>{suggestion.city}</p>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <div className="no-autocomplete">
                        <em>Not found</em>
                    </div>
                );
            }
        }
        return <></>;
    };
    const ToAirPortRenderAutocomplete = () => {
        if (isShowTo && to_airport) {
            if (filtered.length) {
                return (
                    <ul className="autocomplete_to">
                        {filtered?.map((suggestion, index) => {
                            let className;
                            if (index === active) {
                                className = "active";
                            }
                            return (
                                <li
                                    className={className}
                                    key={suggestion.id}
                                    onClick={(e) => {
                                        ToAirPortOnClick(e, suggestion.id, suggestion.name);
                                        var airpot_name = suggestion.name;
                                        localStorageService.set("to_airpot_name", airpot_name);
                                    }}
                                >
                                    <i className="fas fa-plane"></i>
                                    {suggestion.name}
                                    <p>{suggestion.city}</p>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <div className="no-autocomplete">
                        <em>Not found</em>
                    </div>
                );
            }
        }
        return <></>;
    };

    const firstFormValues = JSON.parse(localStorageService.get("firstFormValues"));

    const finalSubmission = useFormik({
        initialValues: {
            from_airport: firstFormValues?.from_airport || "",
            to_airport: firstFormValues?.to_airport || "",
            trip_type: firstFormValues?.trip_type || "",
            departure_date: firstFormValues?.departure_date || "",
            return_date: firstFormValues?.return_date || "",
            travellers: firstFormValues?.travellers || "",
            class: firstFormValues?.class || "",
            name: "",
            email: "",
            phone: "",
            accommodation: true,
        },
        onSubmit: (values) => {
            values.class = firstFormValues.class;
            values.departure_date = firstFormValues.departure_date;
            values.from_airport = firstFormValues.from_airport;
            values.return_date = firstFormValues.return_date;
            values.to_airport = firstFormValues.to_airport;
            values.travellers = firstFormValues.travellers;
            values.trip_type = firstFormValues.trip_type;
            addBooking.mutate(values);
            localStorage.removeItem("firstFormValues");
        },
    });

    return (
        <div>
            {/* <!-- Back To Top Button --> */}
            <a id="back_to_top_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Back to Top"></a>

            {/* <!-- Main Header --> */}
            <nav id="main_header" className="navbar navbar-expand-sm navbar-light sticky-top inner_header">
                <div className="container">
                    <div className="main_header_child">
                        <a className="navbar-brand" href="/">
                            <img src="images/logo.png" alt="Logo" />
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar"
                            aria-controls="offcanvasNavbar"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div
                            className="offcanvas offcanvas-end offcanvasNavbar justify-content-center"
                            tabIndex="-1"
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                        >
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                                    Menu
                                </h5>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                {isFetching ? (
                                    ""
                                ) : (
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href={`tel:${data?.site_settings?.phone}`}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Phone"
                                            >
                                                <div className="d-flex align-items-center">
                                                    <span>
                                                        <div className="header_icons me-2">
                                                            <img src="images/header_phone_icon.png" alt="" className="home_img" />
                                                            <img src="images/header_phone_icon_w.png" alt="" className="inner_img" />
                                                        </div>
                                                    </span>
                                                    {`${data?.site_settings?.phone}`}
                                                </div>
                                            </a>
                                        </li>
                                        <hr />
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                target="_blank"
                                                rel="noreferrer"
                                                href={`${data?.site_settings?.instagram}`}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="Instagram"
                                            >
                                                <span>
                                                    <div className="header_icons">
                                                        <img src="images/header_instagram_icon.png" alt="" className="home_img" />
                                                        <img src="images/header_instagram_icon_w.png" alt="" className="inner_img" />
                                                    </div>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href={`https://api.whatsapp.com/send?phone=${formatPhoneNumber(data?.site_settings?.whatsapp)}`}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                title="WhatsApp"
                                            >
                                                <span>
                                                    <div className="header_icons">
                                                        <img src="images/header_whatsapp_icon.png" alt="" className="home_img" />
                                                        <img src="images/header_whatsapp_icon_w.png" alt="" className="inner_img" />
                                                    </div>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {/* <!-- Mobile Header --> */}
            <div className="mobile_header inner_page_mobile_header">
                <div className="container">
                    <div className="mobile_header_inner">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <a className="navbar-brand" href="/">
                                    <img src="images/logo.png" alt="Logo" />
                                </a>
                            </div>
                            <div className="col-sm-6 text-end">
                                <a className="nav_link" href={`tel:${data?.site_settings?.phone}`}>
                                    <span>
                                        <i className="fa-solid fa-phone me-2"></i>
                                    </span>
                                    {data?.site_settings?.phone}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Inner Banner --> */}
            <div className="inner_banner mobile_d_none">
                <div className="container">
                    <div className="inner_banner_child">
                        <div className="row align-items-end">
                            <div className="col-sm-5">
                                <div className="inner_banner_caption">
                                    <h1>What can I expect from New York</h1>
                                    <div className="inner_banner_caption_img">
                                        <img src="images/tilt_plane.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 offset-sm-3">
                                {!fetching && (
                                    <div className="banner_form">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="directions_box">
                                                    <div className="row">
                                                        <div className="col-sm-2 text-center">
                                                            <div>
                                                                <img src="images/fi1.svg" alt="Plane" />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="left"
                                                                title="Reverse From and To"
                                                            >
                                                                <img onClick={toggledetinations} src="images/switch_location.png" alt="Switch" />
                                                            </button>
                                                            <div>
                                                                <img src="images/fi2.svg" alt="Plane" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-10">
                                                            <input
                                                                className="form-control mb-5"
                                                                placeholder="From"
                                                                id="from_airport"
                                                                onChange={FromAirportChangeHandler}
                                                                onKeyDown={FromAirPortOnKeyDown}
                                                                value={from_airport}
                                                            />
                                                            {FromAirPortRenderAutocomplete()}
                                                            <input
                                                                className="form-control"
                                                                placeholder="To"
                                                                id="to_airport"
                                                                onChange={ToAirPortChangeHandler}
                                                                onKeyDown={ToAirPortOnKeyDown}
                                                                value={to_airport}
                                                                name="to_airport"
                                                            />
                                                            {ToAirPortRenderAutocomplete()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="custom_switch">
                                                    <input className="status" id="status" type="checkbox" name="status" />
                                                    <label htmlFor="status">
                                                        <div
                                                            className="status-switch"
                                                            data-unchecked="One Way"
                                                            data-checked="Round Trip"
                                                            onClick={() => {
                                                                formik.setFieldValue("trip_type", formik.values.trip_type == 1 ? 2 : 1);
                                                            }}
                                                        ></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="date"
                                                        className="form-control datedropper"
                                                        id="departure_date"
                                                        placeholder="Departure"
                                                        value={formik.values.departure_date}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <label htmlFor="departure">Departure</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="date"
                                                        className="form-control datedropper"
                                                        id="return_date"
                                                        placeholder="Return"
                                                        value={formik.values.return_date}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <label htmlFor="return">Return</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-floating">
                                                    <select
                                                        className="form-select"
                                                        id="travellers"
                                                        value={formik.values.travellers}
                                                        onChange={(e) => {
                                                            const name = formData?.traveller_types?.find(
                                                                (obj) => parseInt(obj.id) === parseInt(e.target.value)
                                                            )?.name;
                                                            formik.handleChange(e);
                                                            localStorageService.set("Traveller", name);
                                                        }}
                                                    >
                                                        <option value="">Select Traveller</option>
                                                        {formData?.traveller_types?.map((traveler) => (
                                                            <option value={traveler?.id} key={traveler?.id}>
                                                                {traveler?.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="travellerSelect">Traveller</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-floating">
                                                    <select
                                                        className="form-select"
                                                        id="class"
                                                        value={formik.values.class}
                                                        onChange={(e) => {
                                                            const name = formData?.flight_classes?.find(
                                                                (obj) => parseInt(obj.id) === parseInt(e.target.value)
                                                            )?.name;
                                                            formik.handleChange(e);
                                                            localStorageService.set("flightClass", name);
                                                        }}
                                                    >
                                                        <option value="">Select Flight Class</option>
                                                        {formData?.flight_classes?.map((flight) => (
                                                            <option value={flight.id} key={flight?.id}>
                                                                {flight?.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="classNameSelect">Class Name</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <button
                                                    type="submit"
                                                    className="btn"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#flightBookingModal"
                                                    onClick={formik.handleSubmit}
                                                    disabled={!(formik.isValid && formik.dirty)}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Flight Booking Modal --> */}
            <div className="modal fade custom_modal" id="flightBookingModal" tabIndex="-1" aria-labelledby="flightBookingModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        {!fetching && (
                            <div className="container">
                                <div className="modal-header">
                                    <div className="modal_logo">
                                        <a href="/">
                                            <img src="images/logo.png" alt="Logo" />
                                        </a>
                                    </div>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>

                                <div className="modal-body">
                                    <div className="row align-items-center">
                                        <div className="col-sm-5">
                                            <h1>Explore A New World!</h1>
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder=""
                                                    value={finalSubmission.values.name}
                                                    onChange={finalSubmission.handleChange}
                                                />
                                                <label htmlFor="name">Name</label>
                                            </div>
                                            <div className="form-floating">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Email"
                                                    value={finalSubmission.values.email}
                                                    onChange={finalSubmission.handleChange}
                                                />
                                                <label htmlFor="email">Email</label>
                                            </div>
                                            <div className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="phone"
                                                    placeholder="Phone"
                                                    value={finalSubmission.values.phone}
                                                    onChange={finalSubmission.handleChange}
                                                />
                                                <label htmlFor="phone">Phone</label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultChecked
                                                    id="accommodation"
                                                    value={finalSubmission.values.accommodation}
                                                    onChange={finalSubmission.onChange}
                                                />
                                                <label className="form-check-label" htmlFor="accommodation">
                                                    Check accommodation with FlightInsiders.com
                                                </label>
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn global_btn"
                                                data-bs-toggle="modal"
                                                data-bs-target="#thankYouModal"
                                                onClick={finalSubmission.handleSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                        <div className="col-sm-7">
                                            <div className="card-group">
                                                <div className="card">
                                                    <ul className="list-group">
                                                        <li className="list-group-item">
                                                            <div className="row align-items-center">
                                                                <div className="col-sm-6">
                                                                    <h6>Class Name</h6>
                                                                </div>
                                                                <div className="col-sm-6 text-end">
                                                                    <label className="badge custom_badge">
                                                                        {localStorageService.get("flightClass") ? localStorageService.get("flightClass") : ""}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="row align-items-center">
                                                                <div className="col-sm-5">
                                                                    <h6>From</h6>
                                                                    <h5>
                                                                        {localStorageService.get("fromAirPort") ? localStorageService.get("fromAirPort") : ""}
                                                                    </h5>
                                                                </div>
                                                                <div className="col-sm-2 text-center">
                                                                    <img src="images/mini_plane.png" alt="Plane" />
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <div className="float-end">
                                                                        <h6>To</h6>
                                                                        <h5>
                                                                            {localStorageService.get("toAirPort") ? localStorageService.get("toAirPort") : ""}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="row align-items-center">
                                                                <div className="col-sm-6">
                                                                    <h6>Departure</h6>
                                                                    <h5>{firstFormValues?.departure_date ? firstFormValues?.departure_date : ""}</h5>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="float-end">
                                                                        <h6>Return</h6>
                                                                        <h5>{firstFormValues?.return_date ? firstFormValues?.return_date : ""}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-sm-12">
                                                                    <h6>Traveller</h6>
                                                                    <h5>{localStorageService.get("Traveller") ? localStorageService.get("Traveller") : ""}</h5>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="card">
                                                    <div className="my-4">
                                                        <h6>Name</h6>
                                                        <h4>{finalSubmission.values.name}</h4>
                                                    </div>
                                                    <div className="my-4">
                                                        <h6>Flight</h6>
                                                        <h4>OKL015</h4>
                                                    </div>
                                                    <div className="mt-4">
                                                        <img src="images/barcode.png" alt="Barcode" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* <!-- Mobile Inner Banner --> */}
            <div className="inner_banner_mobile">
                <div className="d-inline-block">
                    <a href="/" className="btn">
                        <img src="images/back_arrow.png" alt="" />
                    </a>
                </div>
            </div>
            <div className="booking_modal_opener">
                <div className="container">
                    <h1>What can I expect from New York</h1>
                    <div className="booking_info_box">
                        <span>New York</span>
                        <img src="images/circles_plane.png" alt="" />
                        <span>London</span>
                    </div>
                    <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#mobileFlightBookingModal">
                        Book Flight
                    </button>
                </div>
            </div>
            {/* <!-- Thank You Modal --> */}
            {(addBooking.isSuccess || 1 == 1) && (
                <div className="modal fade custom_modal custom_modal2" id="thankYouModal" tabIndex="-1" aria-labelledby="thankYouModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="container">
                                <div className="modal-header">
                                    <div className="modal_logo">
                                        <a href="/">
                                            <img src="images/logo.png" alt="Logo" />
                                        </a>
                                    </div>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body text-center">
                                    <img src="images/large_plane.png" alt="Large Plane" />
                                    <h1>Thank You!</h1>
                                    <h5>Your booking request have been submitted successfully!</h5>
                                    <button type="button" className="btn global_btn" data-bs-dismiss="modal">
                                        Go Home
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* <!-- Mobile Flight Booking Modal --> */}
            <div
                className="modal fade custom_modal3 custom_modal5"
                id="mobileFlightBookingModal"
                tabIndex="-1"
                aria-labelledby="mobileFlightBookingModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src="images/back_arrow.png" alt="" />
                            </button>
                            <h2>Flight Info</h2>
                            <div className="directions_box">
                                <div className="row align-items-center">
                                    <div className="col-sm-2">
                                        <div>
                                            <img src="images/fi1.svg" alt="Plane" />
                                        </div>
                                    </div>
                                    <div className="col-sm-10">
                                        <input
                                            className="form-control"
                                            placeholder="From"
                                            id="from_airport"
                                            onChange={FromAirportChangeHandler}
                                            onKeyDown={FromAirPortOnKeyDown}
                                            value={from_airport}
                                        />
                                        {FromAirPortRenderAutocomplete()}
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-sm-10">
                                        <hr />
                                    </div>
                                    <div className="col-sm-2">
                                        <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-placement="left" title="Reverse From and To">
                                            <img onClick={toggledetinations} src="images/switch_location.png" alt="Switch" />
                                        </button>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-sm-2">
                                        <div>
                                            <img src="images/fi2.svg" alt="Plane" />
                                        </div>
                                    </div>
                                    <div className="col-sm-10">
                                        <input
                                            className="form-control"
                                            placeholder="To"
                                            id="to_airport"
                                            onChange={ToAirPortChangeHandler}
                                            onKeyDown={ToAirPortOnKeyDown}
                                            value={to_airport}
                                            name="to_airport"
                                        />
                                        {ToAirPortRenderAutocomplete()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="banner_form">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="custom_switch">
                                            <input className="status" id="status2" type="checkbox" name="status" />
                                            <label htmlFor="status2">
                                                <div
                                                    className="status-switch"
                                                    data-unchecked="One Way"
                                                    data-checked="Round Trip"
                                                    onClick={() => {
                                                        formik.setFieldValue("trip_type", formik.values.trip_type == 1 ? 2 : 1);
                                                    }}
                                                ></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-floating">
                                            <input
                                                type="date"
                                                className="form-control datedropper"
                                                id="departure_date"
                                                placeholder="Departure"
                                                value={formik.values.departure_date}
                                                onChange={formik.handleChange}
                                            />
                                            <label htmlFor="departure">Departure</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-floating">
                                            <input
                                                type="date"
                                                className="form-control datedropper"
                                                id="return_date"
                                                placeholder="Return"
                                                value={formik.values.return_date}
                                                onChange={formik.handleChange}
                                            />
                                            <label htmlFor="return">Return</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-floating">
                                            <select
                                                className="form-select"
                                                id="travellers"
                                                value={formik.values.travellers}
                                                onChange={(e) => {
                                                    const name = formData?.traveller_types?.find((obj) => parseInt(obj.id) === parseInt(e.target.value))?.name;
                                                    formik.handleChange(e);
                                                    localStorageService.set("Traveller", name);
                                                }}
                                            >
                                                <option value="">Select Traveller</option>
                                                {formData?.traveller_types?.map((traveler) => (
                                                    <option value={traveler?.id} key={traveler?.id}>
                                                        {traveler?.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="travellerSelect">Traveler</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-floating">
                                            <select
                                                className="form-select"
                                                id="class"
                                                value={formik.values.class}
                                                onChange={(e) => {
                                                    const name = formData?.flight_classes?.find((obj) => parseInt(obj.id) === parseInt(e.target.value))?.name;
                                                    formik.handleChange(e);
                                                    localStorageService.set("flightClass", name);
                                                }}
                                            >
                                                <option value="">Select Flight Class</option>
                                                {formData?.flight_classes?.map((flight) => (
                                                    <option value={flight.id} key={flight?.id}>
                                                        {flight?.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="classNameSelect">Class Name</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="submit"
                                className="btn"
                                data-bs-toggle="modal"
                                data-bs-target="#mobilePersonalInfoModal"
                                onClick={formik.handleSubmit}
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Mobile Personal Info Modal --> */}
            <div
                className="modal fade custom_modal3"
                id="mobilePersonalInfoModal"
                tabIndex="-1"
                aria-labelledby="mobilePersonalInfoModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src="images/back_arrow.png" alt="" />
                            </button>
                            <h2>Personal Info</h2>
                        </div>
                        <div className="modal-body">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder=""
                                    value={finalSubmission.values.name}
                                    onChange={finalSubmission.handleChange}
                                />
                                <label htmlFor="name">Name</label>
                            </div>
                            <div className="form-floating">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Email"
                                    value={finalSubmission.values.email}
                                    onChange={finalSubmission.handleChange}
                                />
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phone"
                                    placeholder="Phone"
                                    value={finalSubmission.values.phone}
                                    onChange={finalSubmission.handleChange}
                                />
                                <label htmlFor="phone">Phone</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="1" id="accommodation2" />
                                <label className="form-check-label" htmlFor="accommodation2">
                                    Check accommodation with FlightInsiders.com
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="submit"
                                className="btn"
                                data-bs-toggle="modal"
                                data-bs-target="#mobileThankYouModal"
                                onClick={finalSubmission.handleSubmit}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Mobile Thank You Modal --> */}
            {(addBooking.isSuccess || 1 == 1) && (
                <div
                    className="modal fade custom_modal3 custom_modal4"
                    id="mobileThankYouModal"
                    tabIndex="-1"
                    aria-labelledby="mobileThankYouModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="w-100">
                                    <img src="images/mobile_thankyou_modal.png" alt="" />
                                    <h1>Thank You!</h1>
                                    <h5>Your booking request have been submitted successfully!</h5>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn" data-bs-dismiss="modal">
                                    Go Home
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* <!-- Flight Content --> */}
            <div className="flight_content">
                <div className="container">
                    <p>
                        Lorem ipsum proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec
                        sagittis sem nibh id elit.Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a
                        odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. className aptent taciti sociosqu ad litora
                        torquent per conubia nostra, per inceptos himenaeos. Mauris in erat justo. Nullam ac urna eu felis dapibus condimentum sit amet a augue
                        consequat elis.
                    </p>
                    <p>
                        Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis
                        sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio
                        tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit.
                    </p>
                    <h2 className="secondary_heading">TYPICAL COSTS WHEN TRAVELLING</h2>
                    <p>
                        Lorem ipsum proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec
                        sagittis sem nibh id elit.Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a
                        odio tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit. className aptent taciti sociosqu ad litora
                        torquent per conubia nostra, per inceptos himenaeos. Mauris in erat justo. Nullam ac urna eu felis dapibus condimentum sit amet a augue
                        consequat elis.
                    </p>
                    <p>
                        Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis
                        sem nibh id elit. Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a odio
                        tincidunt auctor a ornare odio. Sed non mauris vitae erat consequat auctor eu in elit.
                    </p>
                </div>
            </div>
            {/* <!-- Related Flights --> */}
            <Destinations />
            {/* <!-- Newsletter Footer --> */}
            <Subscription />
            {/* <!-- Main Footer --> */}
            <Footer />
        </div>
    );
}

export default FlightDetail;
