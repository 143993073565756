import { useState } from "react";
import Footer from "../Components/footer";
import Subscription from "../Components/subscription";
import Header from "../Components/header";
import Destinations from "../Components/destinations";
import MainBanner from "../Components/MainBanner";
import Airlines from "../Components/airlines";
import Features from "../Components/features";
import About from "../Components/about";

import "../styles/base.scss";

function Home() {
    const [destinations, setDestinations] = useState({
        from_airport: {
            id: -1,
            name: "",
        },
        to_airport: {
            id: -1,
            name: "",
        },
    });

    return (
        <div>
            {/* <!-- Back To Top Button --> */}
            <a id="back_to_top_btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Back to Top" href="#"></a>
            {/* Main Header --> */}
            <Header />
            {/* <!-- Main Banner --> */}
            <MainBanner destinationData={destinations} />
            {/* <!-- About --> */}
            <About />
            {/* <!-- Recent Flights --> */}
            <Destinations onSetDestinationsHandler={setDestinations} />
            {/* <!-- Features --> */}
            <Features />
            {/* <!-- Airlines --> */}
            <Airlines />
            {/* <!-- Newsletter Footer --> */}
            <Subscription />
            {/* <!-- Main Footer --> */}
            <Footer />
        </div>
    );
}
export default Home;
