import { useGetAllHomePageData, useGetSiteSetting } from "./../queries/hooks/index";
import { IMG_BASE_URL } from "../config";

function About() {
  const { data, isFetching } = useGetAllHomePageData();
  const { data: siteData, isFetching: isFetchingSiteData } = useGetSiteSetting();
  return (
    <div className="about">
      <div className="how_it_works_section_web">
        <div className="container">
          <h2 className="section_heading text-center">How Does It Work?</h2>
          <div className="row">
            <div className="col-sm-4">
              <div className="what_happens_box">
                <i className="fa-solid fa-laptop"></i>
                <div>
                  <h5>Get in touch with our expert</h5>
                  <p>Call us now at {isFetchingSiteData ? (<span className="load"></span>) : (<a href={`tel:${siteData?.site_settings?.phone}`}>{`${siteData?.site_settings?.phone}`}</a>)} or submit your inquiry using the flight search tool on our website!</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="what_happens_box">
                <i className="fa-solid fa-user-tie-hair"></i>
                <div>
                  <h5>One of our Flight Insiders searches the best options for you</h5>
                  <p>Every request is custom tailored by our knowledgeable travel experts to find you the best flights and price!</p>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="what_happens_box">
                <i className="fa-solid fa-plane-up"></i>
                <div>
                  <h5>Receive your options, book, and save</h5>
                  <p>Get a competitive quote within minutes and book your trip today!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="about_inner">
          <div className="row">
            {!isFetching && (
              <>
                <div className="col-sm-5">
                  <h2 className="section_heading">
                    {data?.about_content?.title}
                  </h2>
                  <img
                    src={`${IMG_BASE_URL}pages/${data?.about_content?.image}`}
                    className="about_mobile_img"
                    alt="Plane"
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.about_content?.description,
                    }}
                  />
                </div>
                <div className="col-sm-7 text-end">
                  <img
                    src={`${IMG_BASE_URL}pages/${data?.about_content?.image}`}
                    className="mobile_d_none"
                    alt="Flying aeroplane on a clouds - Flight Insiders"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
