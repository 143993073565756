export const siteSettings = {
    "email": "info@flightinsiders.com",
    "phone": "(212) 680-4905",
    "whatsapp": "12126804905",
    "address": "11 Broadway, New York, NY 10004",
    "facebook": "https://facebook.com/flightinsiders",
    "twitter": null,
    "google_plus": null,
    "instagram": "http://instagram.com/flight.insiders",
    "linkedin": null,
    "instagram_module": {
        "image": "1658927657.jpg",
        "link": "https://www.instagram.com/flight.insiders/",
    }
};